@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Vidaloka&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

html, body, #root {
    height: 100%;
    width: 100%;
}

p {
    font-size: 18px;
    color: #828793;
    margin-bottom: 30px;
    line-height: 26px;
}

p a { color: #828793; text-decoration: underline; }
p a:hover { color: #828793; }

body, input, submit { font-family: 'Open Sans', sans-serif; }

.container { max-width: 1440px; }

h1 {
    font-size: 56px;
    color: #fff;
    font-family: 'Vidaloka', sans-serif;
    text-transform: uppercase;
}
    .subtitle {
        font-size: 46px;
        line-height: 50px;
        color: #fff;
        font-family: 'Vidaloka', sans-serif;
        text-transform: uppercase;
    }

h2 {
    font-size: 48px;
    line-height: 48px;
    color: #b0604f;
    font-family: 'Vidaloka', sans-serif;
}
    
h3 {
    font-family: 'Vidaloka', sans-serif;
    font-size: 39px;
    color: #52545a;
    border-bottom: 2px solid #828693;
    padding-bottom: 22px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.bm-burger-button { display: none; }

.layer {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    background: rgba(0,0,0,.6);
    z-index: 900;
    display: none;
}
.layer .popup {
    width: 900px;
    max-width: 90%;
    height: 425px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    padding: 60px;
    padding-left: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: scale(.8);
}
.layer .popup h2 {
    /* font-size: 34px; */
    /* line-height: 42px; */
    /* margin-right: 40px; */
    /* margin-bottom: 40px; */
    padding-top: 0;
    padding-bottom: 5px;
}
.layer .popup p {
    color: #3a3b41;
    font-size: 16px;
    line-height: 30px;
}
.popup-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.popup-info div { width: 100%; }
.popup-blog { background: url('../assets/bg-popup-site.jpg') left center no-repeat; }

.popup-form { position: relative; width: 100%; }
.popup-form input {
    height: 50px;
    line-height: 50px;
    background: #fff;
    color: #000;
    padding: 0 15px;
    border: none;
    width: 100%;
    text-transform: none;
}

.popup-form input::-webkit-input-placeholder { color: #000; }
.popup-form input::-moz-placeholder { color: #000; }
.popup-form input:-ms-input-placeholder { color: #000; }
.popup-form input:-moz-placeholder { color: #000; }

.popup-form button {
    color: #fff;
    background: #282828;
    padding: 0 20px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    bottom: 15px;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.popup-form button:hover {
    opacity: .8;
}

.popup-close {
    position: absolute;
    top: 0;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    color: #fff;
    cursor: pointer;
    background: #111;
}

.bg-contato iframe, .bg-contato-holder iframe {
    overflow: hidden;
    width: 100%;
    height: 920px !important;
}

@media (max-width: 1600px) {
    header li a { font-size: 16px !important; }
    header li::before { top: 4px !important; }

    .img-psicodelic { width: 300px !important; }
}   

@media (max-width: 1200px) {
    .img-psicodelic { display: none; }
}

@media (max-width: 960px) {
    .bm-burger-button { display: block; }

    h1 {
        font-size: 36px !important; 
    }

    h2 { 
        font-size: 30px !important; 
        line-height: 34px !important;
        padding: 0 !important;
        padding-bottom: 15px !important;
    }

    h3 {
        font-size: 28px !important;
    }

    h4 {
        font-size: 24px !important;
        line-height: 30px !important;
    }

    p {
        font-size: 16px !important;
        line-height: 26px !important;
    }

    header { 
        padding: 28px 0 !important; 
        height: auto !important; 
    }
    header img { max-width: 80%; }

    .hip-blue,
    .bg-brain,
    .bg-beach,
    .bg-blog,
    .bg-contato-holder { 
        margin-top: 100px !important; 
        padding: 30px 0 !important;
    }

    .bg-certificados,
    .hip-rock,
    .hip-yellow,
    .hip-white,
    .bg-strong,
    .color-brain,
    .bg-light,
    .bg-body,
    .bg-heart,
    .bg-spirit,
    .felicidade,
    .bg-projetar {
        padding: 30px 0 !important;
    }

    .bg-egito { 
        height: 300px !important; 
        margin-top: 90px !important;
    }

    .bg-missao {
        margin: 0 !important;
        padding: 0 !important;
    }

    .bg-contato, .bg-contato-holder {
        padding: 30px 0 !important;
        text-align: center !important;
    }

    .bg-contato iframe, .bg-contato-holder iframe {
        overflow: hidden;
        width: 100%;
    }

    .img-left { width: 100% !important; }
    .img-right { width: 100% !important; }

    form .button-default {
        margin-bottom: 60px;
    }

    .text-red i br { display: none; }

    .before-red-box { margin-bottom: 30px !important; }

    .bg-eye span {
        font-size: 22px !important;
        line-height: 40px !important;
    }

    .subtitle {
        font-size: 24px !important;
        line-height: 30px !important;
    }

    .manyfaces img { display: none; }

    .text-highlight {
        font-size: 22px !important;
        line-height: 34px !important;
    }
    .text-highlight span { font-size: 20px !important; }

    .bg-body h5 {
        text-align: left !important;
        margin: 0 0 30px !important;
        font-size: 34px !important;
    }

    .bg-body ul li {
        font-size: 16px !important;
        margin-bottom: 10px;
    }

    .bg-heart img {
        position: relative !important;
        left: 0 !important;
    }

    .left, .right { padding: 0 !important; }

    .bg-spirit img {
        top: 0 !important;
        right: 0 !important;
        margin-bottom: 30px !important;
    }

    p.end { text-align: left !important; }
    .grid-col-2, .grid-col-1 { padding: 0 !important; }

    .border-box { font-size: 16px !important; }

    .blog-title {
        font-size: 22px !important;
        line-height: 28px !important;
    }

    .blog-info {
        color: gray;
        font-size: 14px !important;
        display: inline-block !important;
        float: left;
        margin-left: 0 !important;
        margin-bottom: 20px;
        margin-right: 20px !important;
        text-align: left !important;
    }

    .list-fadiga { left: 0 !important; }

    .bg-chakra .depoimento {
        font-size: 24px !important;
        line-height: 32px !important;
        text-align: center !important;
    }

    .bg-kundalini .img-kundalini-1 {
        right: 0 !important;
        top: -45px !important;
        position: absolute !important;
        width: 250px !important;
    }
    .bg-kundalini .img-kundalini-2 { display: none; }

    .bg-snake .img-snake-1 {
        right: 0 !important;
        bottom: -95px !important;
        position: absolute !important;
        width: 105px !important;
    }

    .bg-snake .img-snake-2 { display: none; }

    .hip-blue {
        background-position: center no-repeat;
    }

    .img-left, .img-right {
        width: 65% !important;
        display: block !important;
        float: none !important;
        margin: 0 auto 30px !important;
    }
}

.link-whats {
    background: url('../assets/icon-whats.png') left center no-repeat;
    display: inline-block;
    padding-left: 40px !important;
    color: #fff;
    margin-bottom: 5px;
    text-decoration: none;
    background-size: 30px;
}
    .link-whats:hover { color: #fff; opacity: 1; }

.bg-new {
    padding: 80px 0;
    background: #e3f6ff url('../assets/bg-new.jpg') left bottom no-repeat;
    background-size: contain;
}
    .bg-new h2 { color: #25566a; font-size: 40px; margin-bottom: 30px; }
    .bg-new h2 span { 
        color: #6a9baf; 
        font-size: 26px; 
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-style: italic;
    }

    .bg-new .list-default { 
        padding: 0; 
        margin: 0; 
        margin-bottom: 50px; 
    }
    .bg-new .list-default li {
        font-size: 15px;
        padding: 0;
        margin: 0;
        margin-left: 20px;
        margin-bottom: 3px;
    }
    .bg-new .list-default li span { 
        color: #6a9baf; 
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-style: italic;
    }
    .p-new span { font-size: 18px; font-weight: 600; color: #252829; font-family: 'Open Sans', sans-serif; }
    .p-new .p-new-blue { font-size: 24px; color: #25566a; font-family: 'Vidaloka', sans-serif; }

    .p-new { 
        display: flex; 
        flex-direction: row; 
        justify-content: space-between; 
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0,0,0,.2);
    }

    .bg-new-mobile { display: none; }

    .interna.bg-new { margin-top: 130px; }

    .bg-new-interna { display: none; }

    .andressa-terapia ul li { margin-bottom: 15px;}

    .indicacoes ul li { font-size: 18px !important;}

    .indicacoes { padding: 20px; margin-top: 20px;}

    .andressa-sobre { padding: 30px 0;}

    .bg-dubai { padding: 30px 0;}

    .andressa-terapia { padding: 30px 0;}

    .andressa-terapia h2 { margin-top: 30px;}

@media (max-width: 961px) {
    header .col-md-9 { display: none !important; }
    .bg-new { display: none; }
    .bg-new-mobile { display: block; }

    .interna.bg-new { display: block; margin-top: 90px; background: #e3f6ff; padding-bottom: 0; }
    .interna.bg-new-mobile { display: none; }
    
    .interna.bg-new-interna { display: block; }

    .p-new { flex-direction: column; align-items: flex-start; }
    .p-new-blue { margin-top: 30px; display: block; }
}

@media (max-width: 768px) {
    .blog-image { height: 220px; }

    .layer { z-index: 2000; }
    .layer .popup { padding: 30px; height: 325px; }
    .popup-info { flex-direction: column; }
    .popup-info br { display: none; }
    .layer .popup h2 { margin-right: 0; margin-bottom: 10px; }
    .popup-blog { background: url('../assets/bg-popup-site.jpg') right center no-repeat; }

    .popup-close { top: -50px; }

    .bg-new .list-default { margin: 0; }
}